export const colors = {
  // FONTS
  fontWhite: "#ffffff",
  fontWelcome: "#c8c5d9",
  fontGrey: "#4e4c54",
  fontTitle: "#160242",
  fontActive: "#3c148f",
  fontDanger: "#ff2b2b",
  fontNotification: "#ff2b2b",
  fontDisabled: "#b9b5db",
  fontSub: "#948ea3",
  // CTA BUTTON
  primary: "#7000ff",
  primary2IdleShadow: "#1a1a1a3f",
  primary2HoverShadow: "#1a1a1a48",
  primaryTransparent: "#7000ffc0",
  primaryHover: "#7831fb",
  primaryActive: "#edeff2",
  primaryDisabled: "#b5b1bf",
  primaryFontClicked: "#ccd1ff",
  // OUTLINE BUTTON
  outline: "#f9f8ff",
  outlineStroke: "#efecff",
  outlineHover: "#c6bcff",
  outlineClick: "#e7e3ff",
  outlineFontClicked: "#4617a8",
  // COMMON
  whiteBG: "#ffffff",
  headerButton: "#4d4765",
  shape: "#edeff2",
  formSpacer: "#e1e1e8",
  spacer: "#d8d8d8",
  galleryTint: "#120620BF",
  galleryTintOpaque: "#413f4644",
  modalfill: "#080314e5",
  disabledText: "#d4d1eb",
  disabledIdle: "#f9f8ff",
  disabledStrike: "#efecff",
  // CHAT
  chatbox: "#f7f6f8",
  dialogs: "#EBE9EE",
  chathover: "#d4d2dd",
  chatoverlay: "#ffffff60",
  chatfont1: "#3d3158",
  chatfont1hover: "#a09cae",
  chatfont2: "#958aab",
  ownermessage: "#7000ff",
  opponentmessage: "#F7F6F8",
  //MODAL
  modalBG: "#080314e5",
};
